import * as React from 'react';
import App from '../app';
/**
 * The `content` at the index.
 */
const IndexPage = () => {
  return <App debug={false} />;
};

export default IndexPage;
